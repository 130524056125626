import React from "react";
import scrollTo from 'gatsby-plugin-smoothscroll';

import { FormattedMessage } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

const OfferHero = () => {

    return (
        <section className="index-hero">
            <div id="offerHero" className="w-screen pt-28 md:pt-48 sm:pb-32 md:pb-0 md:h-screen min-h-[550px] md:min-h-[700px]">
                <div className="flex flex-col justify-center w-screen pb-8 items-center h-[90%] ">
                    <div className="w-full lg:w-1220">
                        <div className="flex flex-col h-full  px-4 md:pt-6">
                            <h1
                                className="text-5xl sm:text-5xl md:text-[6rem] lg:text-[6rem] text-white font-semibold pb-8"
                                id="hero-heading"
                                style={{ lineHeight: 1.3 }}
                            >
                                <FormattedMessage id="offer_hero_heading" />
                            </h1>
                            <h2
                                className="text-xl md:text-3xl font-light text-white pb-8 md:pr-12"
                                style={{ lineHeight: 1.8 }}
                            >
                                <FormattedMessage id="offer_hero_subheading" />
                            </h2>
                            <div className="min-h-[150px]">

                                <button onClick={() => { scrollTo('#offer-form-anchor') }} className="btn-purple-solid w-40 sm:w-40 md:w-60 flex flex-row items-center justify-center py-3 mt-6 lg:mt-0 group text-sm sm:text-xl font-medium tracking-wider">
                                    <FormattedMessage id="get_offer" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-[10%] w-full flex justify-center pb-2 md:pb-0">
                    <div className="w-10 lg:w-14">
                        <StaticImage
                            src={"../../images/icons/arrow_down.svg"}
                            placeholder="blurred"
                            layout="constrained"
                            loading="lazy"
                            alt="Cimate first icon"
                        />
                    </div>
                </div>
            </div >
        </section >
    );
};

export default OfferHero;
