import React from "react";

import { StaticImage } from "gatsby-plugin-image";
import { FormattedMessage } from "gatsby-plugin-react-intl";

const Customers = (props) => {
    return (
        <section className="index-partners">
            <div className={"w-screen " + props.bgClasses}>
                <div className="flex justify-center w-screen pb-12">
                    <div className="w-full lg:max-w-[1800px] flex flex-col items-center">
                        <div className="pt-5 md:pt-8 flex flex-col px-10">
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-12 lg:gap-y-8 gap-x-12 items-center">
                                <h2 className="col-span-2 md:cols-pan-4 lg:col-span-8 text-xl text-gray-500 font-light text-center">
                                    <FormattedMessage id="customers_title" />
                                </h2>
                                <div className="w-14 md:w-28 mx-auto flex flex-col justify-end opacity-90">
                                    <StaticImage
                                        src={"../../images/customer_logos/aquaporin_logo.jpg"}
                                        placeholder="blurred"
                                        layout="constrained"
                                        loading="eager"
                                        alt="Aquaporin"
                                    />
                                </div>
                                <div className="w-14 md:w-32 mx-auto flex flex-col justify-end opacity-90">
                                    <StaticImage
                                        src={"../../images/customer_logos/tff_logo.jpg"}
                                        placeholder="blurred"
                                        layout="constrained"
                                        loading="eager"
                                        alt="The footprint firm"
                                    />
                                </div>
                                <div className="w-14 md:w-20 mx-auto flex flex-col justify-end opacity-90">
                                    <StaticImage
                                        src={"../../images/customer_logos/matter_logo.jpg"}
                                        placeholder="blurred"
                                        layout="constrained"
                                        loading="eager"
                                        alt="Matter"
                                    />
                                </div>
                                <div className="w-14 md:w-32 mx-auto flex flex-col justify-end opacity-100">
                                    <StaticImage
                                        src={"../../images/customer_logos/noie_logo.svg"}
                                        placeholder="blurred"
                                        layout="constrained"
                                        loading="eager"
                                        alt="Nøie"
                                    />
                                </div>
                                <div className="w-28 md:w-46 mx-auto flex flex-col justify-end opacity-100">
                                    <StaticImage
                                        src={"../../images/customer_logos/gardit.png"}
                                        placeholder="blurred"
                                        layout="constrained"
                                        loading="eager"
                                        alt="Gardit"
                                    />
                                </div>
                                <div className="w-28 md:w-40 mx-auto flex flex-col justify-end opacity-100">
                                    <StaticImage
                                        src={"../../images/customer_logos/klimate_logo.png"}
                                        placeholder="blurred"
                                        layout="constrained"
                                        loading="eager"
                                        alt="Klimate"
                                    />
                                </div>
                                <div className="w-14 md:w-20 mx-auto flex flex-col justify-end opacity-100">
                                    <StaticImage
                                        src={"../../images/customer_logos/norfalk_logo.jpg"}
                                        placeholder="blurred"
                                        layout="constrained"
                                        loading="eager"
                                        alt="Norfalk"
                                    />
                                </div>
                                <div className="w-14 md:w-20 mx-auto flex flex-col justify-end opacity-100">
                                    <StaticImage
                                        src={"../../images/customer_logos/sf_logo.jpg"}
                                        placeholder="blurred"
                                        layout="constrained"
                                        loading="eager"
                                        alt="Second foundation"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Customers;
