import React from "react";

import { StaticImage } from "gatsby-plugin-image";
import { FormattedMessage } from "gatsby-plugin-react-intl";

const FeaturedIn = (props) => {
    return (
        <section className="index-partners">
            <div className={"w-screen " + props.bgClasses}>
                <div className="flex justify-center w-screen pb-12">
                    <div className="w-full lg:w-1220 flex flex-col items-center">
                        <div className="pt-5 md:pt-8 flex flex-col px-10">
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-12 lg:gap-y-8 gap-x-12 items-center">
                                <h2 className="sm:col-span-2 lg:col-span-4 text-xl text-gray-500 font-light text-center"><FormattedMessage id="featured_in" /></h2>
                                <div className="w-14 md:w-32 mx-auto flex flex-col justify-end opacity-90">
                                    <StaticImage
                                        src={"../../images/featured_in_logos/borsen_logo.png"}
                                        placeholder="blurred"
                                        layout="constrained"
                                        loading="eager"
                                        alt="Børsen"
                                    />
                                </div>
                                <div className="w-20 md:w-52 mx-auto flex flex-col justify-end opacity-90">
                                    <StaticImage
                                        src={"../../images/featured_in_logos/es_logo.png"}
                                        placeholder="blurred"
                                        layout="constrained"
                                        loading="eager"
                                        alt="ClimateKIC"
                                    />
                                </div>
                                <div className="w-20 md:w-24 mx-auto flex flex-col justify-end opacity-90 hover:opacity-20">
                                    <StaticImage
                                        src={"../../images/featured_in_logos/finans_logo.png"}
                                        placeholder="blurred"
                                        layout="constrained"
                                        loading="eager"
                                        alt="Accura"
                                    />
                                </div>
                                <div className="w-28 md:w-52 mx-auto flex flex-col justify-end opacity-100">
                                    <StaticImage
                                        src={"../../images/featured_in_logos/ew_logo.png"}
                                        placeholder="blurred"
                                        layout="constrained"
                                        loading="eager"
                                        alt="Innovationsfonden"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FeaturedIn;
