import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { FormattedMessage } from "gatsby-plugin-react-intl";

const OfferBenefits = () => {
    return (
        <section className="about-hero">

            <div className="w-screen bg-white px-4 py-16 md:py-32 flex flex-col items-center gap-16 md:gap-24">
                <div className="w-full md:max-w-[1220px] flex flex-col items-center">
                    <div className="">
                        <h2 className="text-xl md:text-3xl font-medium py-1 lg:max-w-5xl"><FormattedMessage id="offer_benefits_heading" /></h2>
                        <h2 className="text-base md:text-xl font-regular py-4 lg:max-w-4xl"><FormattedMessage id="offer_benefits_subheading" /></h2>
                    </div>
                </div>
                <div className="w-full md:max-w-7xl grid grid-cols sm:grid-cols-2 lg:grid-cols-4 gap-y-16 lg:gap-x-12">
                    <div className="flex flex-col gap-5">
                        <div className="w-12 lg:w-16">
                            <StaticImage
                                src={"../../images/icons/money.svg"}
                                placeholder="blurred"
                                layout="constrained"
                                loading="lazy"
                                alt="Cimate first icon"
                            />
                        </div>
                        <h3 className="text-gray-800 text-lg font-semibold">
                            <FormattedMessage id="offer_benefits_benefit_1_heading" />
                        </h3>
                        <p className="text-gray-700 text-base font-light w-full">
                            <FormattedMessage id="offer_benefits_benefit_1_subheading" />
                        </p>
                    </div>
                    <div className="flex flex-col gap-5">
                        <div className="w-12 lg:w-16">
                            <StaticImage
                                src={"../../images/icons/energy.svg"}
                                placeholder="blurred"
                                layout="constrained"
                                loading="lazy"
                                alt="Cimate first icon"
                            />
                        </div>
                        <h3 className="text-gray-800 text-lg font-semibold">
                            <FormattedMessage id="offer_benefits_benefit_2_heading" />
                        </h3>
                        <p className="text-gray-700 text-base font-light w-full">
                            <FormattedMessage id="offer_benefits_benefit_2_subheading" />
                        </p>
                    </div>
                    <div className="flex flex-col gap-5">
                        <div className="w-12 lg:w-16">
                            <StaticImage
                                src={"../../images/icons/decrease.svg"}
                                placeholder="blurred"
                                layout="constrained"
                                loading="lazy"
                                alt="Cimate first icon"
                            />
                        </div>
                        <h3 className="text-gray-800 text-lg font-semibold">
                            <FormattedMessage id="offer_benefits_benefit_3_heading" />
                        </h3>
                        <p className="text-gray-700 text-base font-light w-full">
                            <FormattedMessage id="offer_benefits_benefit_3_subheading" />
                        </p>
                    </div>
                    <div className="flex flex-col gap-5">
                        <div className="w-12 lg:w-16">
                            <StaticImage
                                src={"../../images/icons/write.svg"}
                                placeholder="blurred"
                                layout="constrained"
                                loading="lazy"
                                alt="Cimate first icon"
                            />
                        </div>
                        <h3 className="text-gray-800 text-lg font-semibold">
                            <FormattedMessage id="offer_benefits_benefit_4_heading" />
                        </h3>
                        <p className="text-gray-700 text-base font-light w-full">
                            <FormattedMessage id="offer_benefits_benefit_4_subheading" />
                        </p>
                    </div>

                </div>
            </div>

        </section>
    );
};

export default OfferBenefits;
